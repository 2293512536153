import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useCabineContext } from "../../../context/CabineContext";
import Axios from "../../../_services/caller.service";
import styles from "./UpdateFeatures.module.scss";
import { GiMushroomCloud, GiOverkill } from "react-icons/gi";

import Modal from "../../UiComponents/modals/Modal";
import useModal from "../../../hooks/useModal";
import InputForms from "../../UiComponents/forms/InputForms";
import Button from "../../UiComponents/Button";
import InputPassword from "../../UiComponents/forms/InputPassword";
import { toast } from "react-toastify";

const UpdateFeatures = ({
  cabId,
  majCabine,
  setMajCabine,
  levelLog,
  changeLevelLog,
}) => {
  const { cabine } = useCabineContext(); //all cabine owner
  const [updateNextStart, setUpdateNextStart] = useState(false);
  const [forceRepairOnQuit, setForceRepairOnQuit] = useState(false);
  const [showBridge, setShowBridge] = useState(false);
  const [DeleteDiracLicence, setDiracDeleteLicense] = useState(false);
  const [showDetector, setShowDetector] = useState(false);
  const [allowDebugPath, setAllowDebugPath] = useState(false);
  const [killSwitch, setKillSwitch] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const { isShowing, toggle } = useModal();

  //KILL SWITCH
  const [passwordAdmin1, setPassWordAdmin1] = useState("");
  const [passwordAdmin2, setPassWordAdmin2] = useState("");
  const [showFunctionAtomic, setShowFunctionAtomic] = useState(false);
  const admin1 = "Spooky";
  const admin2 = "Etienne";

  //CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;

  // GET UPDATE NEXT START
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/UpdateNextStart/${cabId}`, { signal })

        .then(function async(response) {
          
          if (response.data.message === "UpdateNextStart none") {
            setUpdateNextStart(false);
            return;
          } else {
            const data = response.data.data[0].fval;
         

            if (data === "1") {
              setUpdateNextStart(true);
            } else {
              setUpdateNextStart(false);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);

  // GET FORCE REPAIR ON QUIT
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/ForceRepairOnQuit/${cabId}`, { signal })

        .then(function async(response) {
         
          if (response.data.message === "ForceRepairOnQuit none") {
            setForceRepairOnQuit(false);
            return;
          } else {
            const data = response.data.data[0].fval;
    

            if (data === "1") {
              setForceRepairOnQuit(true);
            } else {
              setForceRepairOnQuit(false);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);

  // GET SHOW BRIDGE
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/ShowBridge/${cabId}`, { signal })

        .then(function async(response) {
          if (response.data.message === "ShowBridge none") {
            setShowBridge(false);
            return;
          } else {
            const data = response.data.data[0].fval;

            if (data === "1") {
              setShowBridge(true);
            } else {
              setShowBridge(false);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);

  // GET SHOW DECTECTOR
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/ShowDetector/${cabId}`, { signal })

        .then(function async(response) {
          if (response.data.message === "ShowDetector none") {
            setShowDetector(false);
            return;
          } else {
            const data = response.data.data[0].fval;

            if (data === "1") {
              setShowDetector(true);
            } else {
              setShowDetector(false);
            }
          }
        })
        .catch(function (error) {
          //  console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);
  // GET ALLOW DEBUG PATH
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/AllowDebugPath/${cabId}`, { signal })

        .then(function async(response) {
          if (response.data.message === "AllowDebugPath none") {
            setAllowDebugPath(false);
            return;
          } else {
            const data = response.data.data[0].fval;

            if (data === "1") {
              setAllowDebugPath(true);
            } else {
              setAllowDebugPath(false);
            }
          }
        })
        .catch(function (error) {
          //  console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);
  // GET DELETE DIRAC LICENCE
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/DeleteDiracLicence/${cabId}`, { signal })

        .then(function async(response) {
          if (response.data.message === "DeleteDiracLicence none") {
            setDiracDeleteLicense(false);
            return;
          } else {
            const data = response.data.data[0].fval;

            if (data === "1") {
              setDiracDeleteLicense(true);
            } else {
              setDiracDeleteLicense(false);
            }
          }
        })
        .catch(function (error) {
          //  console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);

  // GET KILLSWITCH
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/KillSwitch/${cabId}`, { signal })

        .then(function async(response) {
          if (response.data.message === "KillSwitch none") {
            setKillSwitch(false);

            return;
          } else {
            const data = response.data.data[0].fval;

            if (data === "1") {
              setKillSwitch(true);
            } else {
              setKillSwitch(false);
            }
          }
        })
        .catch(function (error) {
          //  console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, cabine, majCabine]);

  //UPDATE NEXT CALIB
  const setUpdateNextStartInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setUpdateNextStart/${cabId}`, {
            data: "1",
          })

            .then((response) => {
              const data = response.data.data[0].fval;
              
              if (data === "1") {
                setUpdateNextStart(true);
              } else {
                setUpdateNextStart(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.put(`/setUpdateNextStart/${cabId}`, {
            data: "0",
          })

            .then((response) => {
              const data = response.data.data[0].fval;
             
              if (data === "1") {
                setUpdateNextStart(true);
              } else {
                setUpdateNextStart(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };
  //FORCE REPAIR ON QUIT
  const setForceRepairOnQuitInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setForceRepairOnQuit/${cabId}`, {
            data: "1",
          })

            .then((response) => {
              const data = response.data.data[0].fval;
             
              if (data === "1") {
                setForceRepairOnQuit(true);
              } else {
                setForceRepairOnQuit(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.put(`/setForceRepairOnQuit/${cabId}`, {
            data: "0",
          })

            .then((response) => {
              const data = response.data.data[0].fval;
             
              if (data === "1") {
                setForceRepairOnQuit(true);
              } else {
                setForceRepairOnQuit(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };

  const setShowBridgeInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setShowBridge/${cabId}`, {
            data: "1",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setShowBridge(true);
              } else {
                setShowBridge(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.put(`/setShowBridge/${cabId}`, {
            data: "0",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setShowBridge(true);
              } else {
                setShowBridge(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };
  const setShowDetectorInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setShowDetector/${cabId}`, {
            data: "1",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setShowDetector(true);
              } else {
                setShowDetector(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.put(`/setShowDetector/${cabId}`, {
            data: "0",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setShowDetector(true);
              } else {
                setShowDetector(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };

  //SET ALLOW DEBUG PATH
  const setAllowDebugPathInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setAllowDebugPath/${cabId}`, {
            data: "1",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setAllowDebugPath(true);
              } else {
                setAllowDebugPath(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.put(`/setAllowDebugPath/${cabId}`, {
            data: "0",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setAllowDebugPath(true);
              } else {
                setAllowDebugPath(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };


  //SET DELETE DIRAC LICENCE
  const setDeleteDiracLicenceInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setDeleteDiracLicence/${cabId}`, {
            data: "1",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setDiracDeleteLicense(true);
              } else {
                setDiracDeleteLicense(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.put(`/setDeleteDiracLicence/${cabId}`, {
            data: "0",
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              if (data === "1") {
                setDiracDeleteLicense(true);
              } else {
                setDiracDeleteLicense(false);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch((error) => {});
  };

  //SET KILL SWITCH
  const setKillSwitchUpdateInDb = (checked) => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        if (checked) {
          Axios.put(`/setKillSwitch/${cabId}`, {
            data: "1",
            passwordAdmin1: passwordAdmin1,
            passwordAdmin2: passwordAdmin2,
          })

            .then((response) => {
         
              const data = response.data.data[0].fval;

              toast.success("KillSwitch activé");

              if (data === "1") {
                setKillSwitch(true);
                setHasChanged(true);
              } else {
                setKillSwitch(false);
                setHasChanged(true);
              }
            })
            .catch(function (error) {
              toast.error(error.response.data.msg);
            });
        } else {
          Axios.put(`/setKillSwitch/${cabId}`, {
            data: "0",
            passwordAdmin1: passwordAdmin1,
            passwordAdmin2: passwordAdmin2,
          })

            .then((response) => {
              const data = response.data.data[0].fval;

              toast.success("KillSwitch désactivé");

              if (data === "1") {
                setKillSwitch(true);
                setHasChanged(true);
              } else {
                setKillSwitch(false);
                setHasChanged(true);
              }
            })
            .catch(function (error) {
              toast.error(error.response.data.msg);
            });
        }
      })
      .catch((error) => {});
  };

  /* ATOMIC ZONE FONCTION QUI VERIFIE LES MOTS DE PASSE AVANT D'AFFICHER Les Fonctions protégés*/

  const authFunctionAtomic = () => {
    Axios.post(`/authFunctionAtomic/${cabId}`, {
      passwordAdmin1: passwordAdmin1,
      passwordAdmin2: passwordAdmin2,
    })
      .then((response) => {
       
        if (response.data.isVerified) {
          setShowFunctionAtomic(true);
          toast.success("Accès autorisé");
        } else {
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la vérification", error);
        toast.error("Erreur lors de la vérification");
      });
  };

  const closeModal = () => {
    toggle();
    setPassWordAdmin1("");
    setPassWordAdmin2("");
    setShowFunctionAtomic(false);
  };

  return (
    <div className={styles.settingsFeatures}>
      <div className={styles.container}>
        <div className={styles.optional}>
          <h3>Debug :</h3>
          <div className={styles.name}>
            <span>ForceRepairOnQuit</span>

            <div
              // get cabId and User ID
              className={styles.containerCheckBox}
            >
              <input
                type="checkbox"
                onChange={(e) => setForceRepairOnQuitInDb(e.target.checked)}
                checked={forceRepairOnQuit}
              />
              <label></label>
            </div>
          </div>
          <div className={styles.name}>
            <span>AskUpdate</span>

            <div
              // get cabId and User ID
              className={styles.containerCheckBox}
            >
              <input
                type="checkbox"
                onChange={(e) => setUpdateNextStartInDb(e.target.checked)}
                checked={updateNextStart}
              />
              <label></label>
            </div>
          </div>
          <div className={styles.name}>
            <span>ShowBridge</span>
            <div className={styles.containerCheckBox}>
              <input
                type="checkbox"
                onChange={(e) => setShowBridgeInDb(e.target.checked)}
                checked={showBridge}
              />
              <label></label>
            </div>
          </div>
          <div className={styles.name}>
            <span>ShowDetector</span>

            <div className={styles.containerCheckBox}>
              <input
                type="checkbox"
                onChange={(e) => setShowDetectorInDb(e.target.checked)}
                checked={showDetector}
              />
              <label></label>
            </div>
          </div>
          <div className={styles.name}>
            <span>AllowDebugPath</span>

            <div className={styles.containerCheckBox}>
              <input
                type="checkbox"
                onChange={(e) => setAllowDebugPathInDb(e.target.checked)}
                checked={allowDebugPath}
              />
              <label></label>
            </div>
          </div>{" "}
          <div className={styles.name}>
            <span>DeleteDiracLicense</span>

            <div className={styles.containerCheckBox}>
              <input
                type="checkbox"
                onChange={(e) => setDeleteDiracLicenceInDb(e.target.checked)}
                checked={DeleteDiracLicence}
              />
              <label></label>
            </div>
          </div>{" "}
          <div>
            {cabine && (
              <div className={styles.setLogging}>
                <span>set Logging Level</span>
                <select
                  // value={sortedLevelLogging(cabId)}
                  value={levelLog}
                  onChange={(e) => changeLevelLog(e)}
                  name=""
                  id=""
                >
                  <option value="0">None</option>
                  <option value="1">Standard</option>
                  <option value="2">Critical</option>
                  <option value="3">Full</option>
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.containerKillSwitchButton}>
        <GiMushroomCloud
          style={
            killSwitch
              ? { color: "orange", cursor: "pointer", fontSize: "45px" }
              : { color: "black", cursor: "pointer", fontSize: "45px" }
          }
          onClick={toggle}
        />
      </div>

      {/* MODAL KILLSWITCH */}
      <Modal isShowing={isShowing} hide={closeModal}>
        {!showFunctionAtomic ? (
          <>
            <div className={styles.containerModal}>
              <div className={styles.avertissement}>
                <GiMushroomCloud
                  style={{
                    color: "orange",
                    cursor: "pointer",
                    fontSize: "55px",
                  }}
                />
                <span>Attention vous êtes dans une Zone atomique ! </span>
                <span className={styles.adminName}>
                  Entrez Les mots de passe <strong>{admin1}</strong> et{" "}
                  <strong>{admin2}</strong> pour accéder aux fonctionnalités
                  protégées
                </span>
              </div>

              <div className={styles.containerInput}>
                <InputForms
                  id={styles.pass1}
                  placeholder={`Mot de passe ${admin1}`}
                  type="password"
                  onChange={(e) => setPassWordAdmin1(e.target.value)}
                  required={true}
                />
                <InputForms
                  id={styles.pass2}
                  placeholder={`Mot de passe ${admin2}`}
                  type="password"
                  onChange={(e) => setPassWordAdmin2(e.target.value)}
                  required={true}
                />
              </div>

              <Button
                onPress={authFunctionAtomic}
                styles={"btnPrimary"}
                svg={<GiOverkill />}
                text={"Accéder aux fonctionnalités protégées"}
              />
            </div>{" "}
          </>
        ) : (
          <div className={styles.optionalKill}>
            <div className={styles.kill}>
              <span>KillSwitch</span>

              <div
                // get cabId and User ID
                className={styles.containerCheckBox}
              >
                <input
                  type="checkbox"
                  onChange={(e) => setKillSwitchUpdateInDb(e.target.checked)}
                  checked={killSwitch}
                />
                <label></label>
              </div>
            </div>
            {hasChanged && (
              <div className={styles.containerImg}>
                {killSwitch ? (
                  <img src="https://media.giphy.com/media/xTiIzrRyvrFijaEtY4/giphy.gif" />
                ) : (
                  <img src="https://media.giphy.com/media/3o84sBJxpPt6g6MRdC/giphy.gif" />
                )}
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UpdateFeatures;
