//STYLES
import styles from "./Navbar.module.scss";

//CONTEXT
import { useContext, useState } from "react";
import NavContext from "../../context/NavContext";

//REACT ROUTER
import { Navigate, NavLink, useNavigate } from "react-router-dom";

//ICONS
import {
  MdOutlineDashboard,
  MdQueryStats,
  MdOutlinedFlag,
  MdPeopleOutline,
  MdOutlineMessage,
  MdOutlineLogout,
  MdOutlineCompassCalibration,
  MdSettings,
  MdOutlineAdminPanelSettings,
  MdHelpCenter,
  MdOutlineManageAccounts,
  MdDownload,
  MdEmail,
} from "react-icons/md";
import { FcStatistics } from "react-icons/fc";
import { BsTelephoneInboundFill } from "react-icons/bs";
import { VscNotebookTemplate } from "react-icons/vsc";
import { AiOutlineTable } from "react-icons/ai";
import { GrAddCircle } from "react-icons/gr";
import { FaReact, FaTimes } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { VscDashboard } from "react-icons/vsc";
import { FaMicrophoneAlt,FaFileAudio  } from "react-icons/fa";
import { RiBillLine } from "react-icons/ri";
import { GrLicense } from "react-icons/gr";
import { GrCertificate } from "react-icons/gr";
import { MdSpatialAudio } from "react-icons/md";



const NavUrl = ({ url, icon, icon2, description }) => {
  const { nav, setNav } = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  return (
    <li className={styles.li_navlink} title={nav === false ? description : ""}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => checkWindowSize()}
      >
        {icon}
        <span className={styles.description}>{description}</span>
      </NavLink>
      <span className={styles.icon2}>{icon2}</span>
    </li>
  );
};

const Navbar = () => {
  const { nav, setNav, superAdmin, user, userGroup } = useContext(NavContext);

  const navigate = useNavigate();

  const authModifyCabname =
    userGroup && userGroup.permission.includes("changeCabName");

  return (
    <>
      <div
        className={`${styles.navbar_container} ${
          nav ? styles.navbar_mobile_active : undefined
        }`}
      >
        <nav className={nav ? undefined : styles.nav_small}>
          {/* MENU */}
          <ul className={styles.menu_container}>
            {/* FIRST CATEGORY */}
            {/* <span className={styles.categories}>
            {nav ? "Pages" : <BsThreeDots />}
          </span> */}
            {superAdmin && (
              <NavUrl
                url="/private/superAdmin"
                icon={<MdOutlineAdminPanelSettings />}
                description="Administrateur"
              />
            )}
            {superAdmin && (
              <div className={styles.subMenu}>
                <NavUrl
                  url="/private/superAdmin/manageUsers"
                  icon={<AiOutlineTable />}
                  description="Manage Users"
                />
                <NavUrl
                  url="/private/superAdmin/manageUserPreset"
                  icon={<VscNotebookTemplate />}
                  description="Manage Preset"
                />
                <NavUrl
                  url="/private/superAdmin/manageMics"
                  icon={<FaMicrophoneAlt />}
                  description="Manage Mics"
                />
                <NavUrl
                  url="/private/superAdmin/diracLicence"
                  icon={<GrLicense />}
                  description="Dirac Licence"
                />
                {/* <NavUrl
                  url="/private/superAdmin/wordsLicence"
                  icon={<FaFileAudio />}
                  description="words Licence"
                /> */}
                <NavUrl
                  url="/private/superAdmin/manageCertificat"
                  icon={<GrCertificate />}
                  description="Manage Certificat"
                />
                <NavUrl
                  url="/private/superAdmin/statistics"
                  icon={<FcStatistics />}
                  description="Statistics"
                />
              </div>
            )}

            <NavUrl
              url="/private/dashboard"
              icon={<MdQueryStats />}
              description="Dashboard"
            />
     
            <NavUrl
              url="/private/calibration"
              icon={<MdOutlineCompassCalibration />}
              description="Certificat de calibration"
            />
          
            {!user && (
              <NavUrl
                url="/private/team"
                icon={<MdPeopleOutline />}
                description="Utilisateurs"
              />
            )}
            {(authModifyCabname || superAdmin) && (
              <NavUrl
                url="/private/abonnements"
                icon={<RiBillLine />}
                description="Gestions des cabines"
              />
            )}

            {!user && (
              <NavUrl
                url="/private/download"
                icon={<MdDownload />}
                description="Télechargements"
              />
            )}

            <NavUrl
              url="/private/mon-compte"
              icon={<MdOutlineManageAccounts />}
              description="Mon compte"
            />
            <NavUrl
              url="/private/faq"
              icon={<MdHelpCenter />}
              description="FAQ ( Vidéos d'aide )"
            />
             {/* {superAdmin && (

<NavUrl
              url="/private/supportHS"
              icon={<BsTelephoneInboundFill />}
              description="Support"
            />


            )}  */}

            {/* SECOND CATEGORY */}
            {/* <span className={`${styles.categories} ${styles.second_category}`}>
            {nav ? "More" : <BsThreeDots />}
          </span> */}

            {/* <NavUrl url="/pirvate/settings" icon={<MdSettings onClick={()=>setNav(!nav)}/>} description="Réglages" /> */}

            <div
              className={`${styles.btn_logout}`}
              onClick={() => {
                setNav(!nav);
              }}
            >
              <MdOutlineLogout />
            </div>
          </ul>
        </nav>

        <div
          className={nav ? styles.mobile_nav_background_active : undefined}
          onClick={() => {
            setNav(!nav);
          }}
        ></div>
      </div>
    </>
  );
};

export default Navbar;
