import React, { useContext, useEffect, useState } from "react";
import CardDownload from "./CardDownload";
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";
import { BsMusicNoteList, BsBook, BsFillCloudUploadFill } from "react-icons/bs";
import { HiOutlineShieldExclamation } from "react-icons/hi";
import { GrCloudSoftware } from "react-icons/gr";
import styles from "./DownloadOwner.module.scss";
import axios from "axios";
import NavContext from "../../context/NavContext";
import FileDownload from "js-file-download";
import AxiosDownload from "../../_services/download.service";
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
import ProgressBarDownload from "./ProgressBarDownload";
import Axios from "../../_services/caller.service";

const DownloadOwner = () => {
  const {
    ownerId,
    progressWord,
    setProgressWord,
    setProgressHearing,
    progressHearing,
    setProgressManual,
    progressManual,
    adminProfile,
  } = useContext(NavContext);

  const [mobile, setMobile] = useState(false);
  const [restriction, setRestriction] = useState("");

  //GET USER GROUP AND RESTRICTION OR PERMISSIONS

  useEffect(() => {
    Axios.get(`/getOwnerGroup/${ownerId}`)
      .then((response) => {
        const userRestriction = response.data.userGroup.restriction;

        setRestriction(userRestriction);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, [ownerId]);

  // MANUAL
  const downloadManual = () => {
    window.open("https://hearing-space.com/hs-manual", "_blank");
  };

  //HEARING
  const downloadHearing = (e) => {
    if (progressHearing > 0 && progressHearing < 100) {
      return;
    }

    AxiosDownload.get(`/dwHearing/${ownerId}`, {
      onDownloadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgressHearing(percentCompleted);
      },
    })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];

        let filename = "HearingSpaceSetup.zip"; // un nom par défaut au cas où
        if (contentDisposition) {
          const matches = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches.length > 1) {
            filename = matches[1].replace(/['"]/g, ""); // supprime les guillemets
          }
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  //WORDS
  const downloadWords = (e) => {
    if (progressWord > 0 && progressWord < 100) {
      return;
    }

    AxiosDownload.get(`/dwWords/${ownerId}`, {
      onDownloadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgressWord(percentCompleted);
      },
    })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];

        let filename = "HearingSpaceFrenchWords.zip"; // un nom par défaut au cas où
        if (contentDisposition) {
          const matches = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches.length > 1) {
            filename = matches[1].replace(/['"]/g, ""); // supprime les guillemets
          }
        }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  // //WORDS
  // const downloadWords = (e) => {
  //   if (progressWord > 0 && progressWord < 100) {
  //     return;
  //   }
  //   AxiosDownload.get(`/dwWords/${ownerId}`, {
  //     onDownloadProgress: function (progressEvent) {
  //       const percentCompleted = Math.round(
  //         (progressEvent.loaded * 100) / progressEvent.total
  //       );
  //       setProgressWord(percentCompleted);
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "HearingSpaceFrenchWords.zip");
  //       document.body.appendChild(link);
  //       link.click();
  //     })

  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  return (
    <motion.div
      variants={animatePage}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={styles.container}
    >
      <CardHeaderPage text="Mes Téléchargements" />

      <div className={styles.containerCard}>
        {!mobile && !restriction.includes("download") && (
          <>
            <CardDownload
              onClick={downloadHearing}
              title={"Hearing Space"}
              svg={<BsFillCloudUploadFill />}
              content={"Hearing space"}
              progressBar={progressHearing}
            />

            <CardDownload
              onClick={downloadWords}
              title={"Liste du CNA"}
              svg={<BsMusicNoteList />}
              content={"Liste des mots du Collège National d'Audioprothèse"}
              progressBar={progressWord}
            />
          </>
        )}
        <CardDownload
          onClick={downloadManual}
          title={"Manuel d'utilisation"}
          svg={<BsBook />}
          content={"Manuel d'utlisation"}
          progressBar={progressManual}
        />
      </div>

      {(progressHearing || progressManual || progressWord !== 0) &&
        (progressHearing || progressManual || progressWord !== 100) && (
          <div className={styles.messageUser}>
            <HiOutlineShieldExclamation
              style={{ fontSize: "35px", color: "orange" }}
            />
            <p>
              Veuillez ne pas quitter cette page ni la rafraîchir pendant le
              téléchargement en cours. <br />
              Cela annulera le processus de téléchargement.
              <br />
              Veuillez patienter jusqu'à ce que le téléchargement soit terminé.
            </p>
          </div>
        )}
    </motion.div>
  );
};

export default DownloadOwner;
